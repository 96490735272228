html,
body {
  height: 100%;
  background-color: #f7f7f6;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.section-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section {
  width: 540px;
  margin: 0 auto;
  text-align: left;
}

.section-content {
  background-color: #ffffff;
}

.section-extra {
  padding: 20px;
}

.content {
  padding-left: 5em;
  padding-right: 5em;
  padding-bottom: 2em;
  padding-top: 1em;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: #000000;
}

.status-header {
  padding-top: 1em;
}

.captcha-text {
  padding-left: 5em;
  padding-right: 5em;
}

.notice-text {
  font-family: FortumSans, arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 1em;
}

/* TODO: Align stripe elements properly */
.stripe-element {
  font-family: FortumSans, arial, sans-serif;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  background: #ffffff;
  border-color: #bebec2;
  border-style: solid;
  border-width: 0.125rem;
  border-radius: 0;
  box-shadow: none;
  color: #41414a;
  font-size: 1.125rem;
  height: 4rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  -webkit-transition: background 250ms, border-color 300ms;
  transition: background 250ms, border-color 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  width: 100%;
  padding-top: 20px;
  margin-top: 20px;
}

.stripe-element.StripeElement--invalid {
  border-color: #eb6437;
}

.stripe-element.StripeElement--focus,
.stripe-element.StripeElement:hover {
  border-color: #5ac37d;
}

.notification-container {
  position: fixed;
  top: 40px;
  width: 100%;
  max-width: 540px;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2em;
}

.row-container {
  display: flex;
  align-items: center;
}

.icon-container {
  display: flex;
  margin-right: 10px;
}

.icon-circle {
  width: 48px;
  height: 48px;
  border-radius: 30px;
  border: 2px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  height: 2px;
  width: 100%;
  background-color: #f3f3f3;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (max-width: 540px) {
  .section-container {
    justify-content: flex-start;
  }

  .section {
    width: 100%;
  }

  .section.cover {
    flex: 1;
  }

  .content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
